export const zhValidationMessages: any = { // Chinese error messages
  required: 'å¿å¡«å­æ®µ.',
  minLength: 'å­ç¬¦é¿åº¦å¿é¡»å¤§äºæèç­äº {{minimumLength}} (å½åé¿åº¦: {{currentLength}})',
  maxLength: 'å­ç¬¦é¿åº¦å¿é¡»å°äºæèç­äº {{maximumLength}} (å½åé¿åº¦: {{currentLength}})',
  pattern: 'å¿é¡»å¹éæ­£åè¡¨è¾¾å¼: {{requiredPattern}}',
  format: function (error) {
    switch (error.requiredFormat) {
      case 'date':
        return 'å¿é¡»ä¸ºæ¥ææ ¼å¼, æ¯å¦ "2000-12-31"';
      case 'time':
        return 'å¿é¡»ä¸ºæ¶é´æ ¼å¼, æ¯å¦ "16:20" æè "03:14:15.9265"';
      case 'date-time':
        return 'å¿é¡»ä¸ºæ¥ææ¶é´æ ¼å¼, æ¯å¦ "2000-03-14T01:59" æè "2000-03-14T01:59:26.535Z"';
      case 'email':
        return 'å¿é¡»ä¸ºé®ç®±å°å, æ¯å¦ "name@example.com"';
      case 'hostname':
        return 'å¿é¡»ä¸ºä¸»æºå, æ¯å¦ "example.com"';
      case 'ipv4':
        return 'å¿é¡»ä¸º IPv4 å°å, æ¯å¦ "127.0.0.1"';
      case 'ipv6':
        return 'å¿é¡»ä¸º IPv6 å°å, æ¯å¦ "1234:5678:9ABC:DEF0:1234:5678:9ABC:DEF0"';
      // TODO: add examples for 'uri', 'uri-reference', and 'uri-template'
      // case 'uri': case 'uri-reference': case 'uri-template':
      case 'url':
        return 'å¿é¡»ä¸º url, æ¯å¦ "http://www.example.com/page.html"';
      case 'uuid':
        return 'å¿é¡»ä¸º uuid, æ¯å¦ "12345678-9ABC-DEF0-1234-56789ABCDEF0"';
      case 'color':
        return 'å¿é¡»ä¸ºé¢è²å¼, æ¯å¦ "#FFFFFF" æè "rgb(255, 255, 255)"';
      case 'json-pointer':
        return 'å¿é¡»ä¸º JSON Pointer, æ¯å¦ "/pointer/to/something"';
      case 'relative-json-pointer':
        return 'å¿é¡»ä¸ºç¸å¯¹ç JSON Pointer, æ¯å¦ "2/pointer/to/something"';
      case 'regex':
        return 'å¿é¡»ä¸ºæ­£åè¡¨è¾¾å¼, æ¯å¦ "(1-)?\\d{3}-\\d{3}-\\d{4}"';
      default:
        return 'å¿é¡»ä¸ºæ ¼å¼æ­£ç¡®ç ' + error.requiredFormat;
    }
  },
  minimum: 'å¿é¡»å¤§äºæèç­äºæå°å¼: {{minimumValue}}',
  exclusiveMinimum: 'å¿é¡»å¤§äºæå°å¼: {{exclusiveMinimumValue}}',
  maximum: 'å¿é¡»å°äºæèç­äºæå¤§å¼: {{maximumValue}}',
  exclusiveMaximum: 'å¿é¡»å°äºæå¤§å¼: {{exclusiveMaximumValue}}',
  multipleOf: function (error) {
    if ((1 / error.multipleOfValue) % 10 === 0) {
      const decimals = Math.log10(1 / error.multipleOfValue);
      return `å¿é¡»æ ${decimals} ä½ææ´å°çå°æ°ä½`;
    } else {
      return `å¿é¡»ä¸º ${error.multipleOfValue} çåæ°`;
    }
  },
  minProperties: 'é¡¹ç®æ°å¿é¡»å¤§äºæèç­äº {{minimumProperties}} (å½åé¡¹ç®æ°: {{currentProperties}})',
  maxProperties: 'é¡¹ç®æ°å¿é¡»å°äºæèç­äº {{maximumProperties}} (å½åé¡¹ç®æ°: {{currentProperties}})',
  minItems: 'é¡¹ç®æ°å¿é¡»å¤§äºæèç­äº {{minimumItems}} (å½åé¡¹ç®æ°: {{currentItems}})',
  maxItems: 'é¡¹ç®æ°å¿é¡»å°äºæèç­äº {{maximumItems}} (å½åé¡¹ç®æ°: {{currentItems}})',
  uniqueItems: 'ææé¡¹ç®å¿é¡»æ¯å¯ä¸ç',
  // Note: No default error messages for 'type', 'const', 'enum', or 'dependencies'
};
